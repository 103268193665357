<template>
  <div class="min-h-screen bg-white">
    <main>
      <div>
        <!-- Hero card -->
        <div class="relative">
          <div class="absolute inset-x-0 bottom-0 h-1/2 bg-gray-100"></div>
          <div class="max-w-7xl mx-auto sm:px-6 lg:px-8">
            <div class="relative shadow-xl sm:rounded-2xl sm:overflow-hidden">
              <div class="absolute inset-0">
                <img class="h-full w-full object-cover" src="https://newayatradeinapp.s3-accelerate.amazonaws.com/AdobeStock_252378049.jpeg" alt="Smart devices">
                <div class="absolute inset-0 bg-orange-900 mix-blend-multiply"></div>
              </div>
              <div class="relative px-4 py-16 sm:px-6 sm:py-24 lg:py-32 lg:px-8">
                <h1 class="text-center text-4xl font-extrabold tracking-tight sm:text-5xl lg:text-6xl">
                  <span class="block text-white">Trade-in & CPO</span>
                  <!-- <vue-writer :array="arr" :typeSpeed="150" class="xs:hidden"> -->
                    <span class="is-typed text-orange-400">for regional carriers</span>
                  <!-- </vue-writer> -->
                </h1>
                <p class="mt-6 max-w-lg mx-auto text-center text-xl text-white sm:max-w-3xl">
                  Newaya builds trade-in promotions for regional carriers and MVNOs with enterprise technology, from design to logistics.
                </p>
                <div class="mt-10 max-w-sm mx-auto sm:max-w-none sm:flex sm:justify-center">
                  <div class="space-y-4 sm:space-y-0 sm:mx-auto">
                    <a href="https://meetings.hubspot.com/adamtaylornewaya/newayatradedemo" class="flex items-center justify-center px-4 py-3 border border-transparent text-base font-medium rounded-md shadow-sm text-white bg-orange-400 bg-opacity-60 hover:bg-opacity-70 sm:px-8">
                      Schedule demo
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <carrier-logo-cloud />
      <home-feature />
      <luke-testimonial />
      <program-upgrade-cta />
    </main>
  </div>
</template>

<script>
import { Popover, PopoverButton, PopoverGroup, PopoverPanel } from '@headlessui/vue'
import {
  MenuIcon,
  XIcon,
} from '@heroicons/vue/outline'
import { ChevronDownIcon } from '@heroicons/vue/solid';
import HomeFeature from '../components/HomeFeature.vue';
import LukeTestimonial from '../components/LukeTestimonial.vue';
import ProgramUpgradeCta from '../components/ProgramUpgradeCta.vue';
import CarrierLogoCloud from '../components/CarrierLogoCloud.vue';
import axios from 'axios'

export default {
  name: "Home",
  components: {
    HomeFeature,
    LukeTestimonial,
    ProgramUpgradeCta,
    CarrierLogoCloud,
    Popover,
    PopoverButton,
    PopoverGroup,
    PopoverPanel,
    ChevronDownIcon,
    MenuIcon,
    XIcon,
  },
  methods: {
    submit: function() {
      // fetch("https://script.google.com/a/macros/newaya.com/s/AKfycbxrbbxBSMCX4olgO6kNtkAclkuVdmBRLZxdVkQI4FcFIWy8mP8HuD804OlpfpyZPB95ZQ/exec", 
      // {
      //   method: 'POST',
      //   mode: 'no-cors',
      //   body: JSON.stringify({'email': 'fuck@you.com'}),
      //   });
      // window.open("https://8090484.fs1.hubspotusercontent-na1.net/hubfs/8090484/NewayaViaero%20Case%20Study.pdf", "_blank");
    }
  },
  data() {
    return { arr: ["wireless carriers", "IT Asset Managers", "schools", "consumers"] };
  },
  setup() {
    return {
    }
  },
}
</script>